import styled from 'styled-components';
import React from 'react';
import { useEffect } from 'react';
import Aos from 'aos';

const Titleexpertise = () => {
    useEffect(() => {
        Aos.init({duration: 2000});
    }, [])
    return (
        <Container data-aos='fade-up'>
            <Postitle>
                <Texttitle>NOS EXPERTISES</Texttitle>
                <Text>DEVELOPPEMENT AGILE, PRODUCT MANAGEMENT,<br/> DATA & INTELLIGENCE ARTIFICIELLE (IA)</Text>
            </Postitle>
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    margin-top: 5rem;
    flex-direction: column;
    align-items: center;
    text-align: center;
`;

const Postitle = styled.div `
    text-align: center;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 750px;
    margin: 0 auto;
`;

const Texttitle = styled.h1`
    cursor: pointer;
    text-decoration: none;
    color: black;
    font-size: 70px;
    font-family: 'Greycliff CF';
    margin-bottom: 2rem;
`;

const Text = styled.h2`
    cursor: pointer;
    text-decoration: none;
    color: #7E46AD;
    font-size: 28px;
    font-family: 'Greycliff CF';
    font-weight: 700;
    margin-bottom: 5rem;
    max-width: 750px;
    @media screen and (max-width: 940px) {
        margin-left: 5%;
        margin-right: 5%;
    }
`;


export default Titleexpertise;
