import styled, { createGlobalStyle, css } from "styled-components";
import LogoTel from "../../assets/pictos-telephone.webp";
import LogoMail from "../../assets/pictos-mail.webp";
import LogoLieu from "../../assets/pictos-lieu.webp";
import Fade from "react-reveal/Fade";
import emailjs from "emailjs-com";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Globalstyle = createGlobalStyle`
  * {
    font-family: 'Nunito Sans', sans-serif;
    font-weight:400;
  }
`;

const Containersecondpage = styled.div`
  min-height: 70vh;
`;

const Balise = styled.div`
  width: 1%;
  height: 1%;
  position: sticky;
  border: 3px solid transparent;
`;

const TitleLeft = styled.div`
  padding: 0px 6%;
  padding-top: 150px;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 1100px) {
    width: 100%;
    text-align: center;
    padding: 0%;
  }
`;
const Title = styled.div`
  ${(props) =>
    props.main &&
    css`
      font-size: 55px;
      font-weight: 800;
    `}
  ${(props) =>
    props.sub &&
    css`
      font-size: 20px;
      font-weight: 700;
    `}
`;

const Info = styled.div`
  padding: 100px 6%;
  padding-bottom: 0;
  display: grid;
  grid-template-columns: 2fr 4fr;
  grid-template-rows: 1fr 0.2fr;
  grid-column-gap: 30px;
  grid-row-gap: 20px;
  height: 50vh;

  @media (max-width: 1100px) {
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding-bottom: 40px;
    height: auto;
  }
  @media (max-height: 900px) {
    padding-bottom: 50px;
  }
  @media (max-height: 800px) {
    padding-bottom: 100px;
  }
  @media (max-height: 700px) {
    padding-bottom: 150px;
  }
  @media (max-height: 600px) {
    padding-bottom: 200px;
  }
  @media (max-height: 500px) {
    padding-bottom: 250px;
  }
  @media (max-height: 400px) {
    padding-bottom: 300px;
  }
  @media (max-height: 300px) {
    padding-bottom: 350px;
  }
  @media (max-height: 200px) {
    padding-bottom: 400px;
  }
  @media (max-height: 100px) {
    padding-bottom: 450px;
  }
`;

const Contact = styled.div`
  display: grid;
  grid-template-columns: 1fr 3fr;
  grid-template-rows: repeat(3, 1fr);
  @media screen and (max-width: 1100px) {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  margin-bottom: 75px;
`;

const ContactInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  & p {
    font-weight: 700;
    padding: 6px 0;
  }
`;

const ContactIcon = styled.img`
  height: 71px;
  width: 71px;
`;
const ContactLink = styled.a`
  width: fit-content;
  text-decoration: none;
  color: inherit;
`;

const Formulaire = styled.div`
  font-size: 18px;
`;

const SectionInput = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(3, 0.5fr);
  grid-column-gap: 50px;
  grid-row-gap: 30px;
  @media (max-width: 1100px) {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(5, 0.2fr);
  }
`;

const Input = styled.div`
  & input {
    width: 100%;
    padding: 12px;
    border: solid 1px #dfdfdf;
    border-radius: 8px;
    box-sizing: border-box;
    margin-top: 5px;
    ::placeholder {
      font-size: 14px;
    }
  }
  & textarea {
    width: 100%;
    padding: 12px;
    border-radius: 8px;
    box-sizing: border-box;
    border: solid 1px #dfdfdf;
    margin-top: 5px;
  }
  &:nth-child(1) {
    grid-area: 1 / 1 / 2 / 2;
  }
  &:nth-child(2) {
    grid-area: 1 / 2 / 2 / 3;
  }
  &:nth-child(3) {
    grid-area: 2 / 1 / 3 / 2;
  }
  &:nth-child(4) {
    grid-area: 2 / 2 / 3 / 3;
  }

  @media (max-width: 1100px) {
    &:nth-child(1) {
      grid-area: 1 / 1 / 2 / 2;
    }
    &:nth-child(2) {
      grid-area: 2 / 1 / 3 / 2;
    }
    &:nth-child(3) {
      grid-area: 3 / 1 / 4 / 2;
    }
    &:nth-child(4) {
      grid-area: 4 / 1 / 5 / 2;
    }
  }
`;

const Inputmessage = styled.div`
  & input {
    width: 100%;
    padding: 12px;
    border: solid 1px #dfdfdf;
    border-radius: 8px;
    box-sizing: border-box;
    margin-top: 5px;
    ::placeholder {
      font-size: 14px;
    }
  }
  & textarea {
    width: 100%;
    padding: 12px;
    border-radius: 8px;
    box-sizing: border-box;
    border: solid 1px #dfdfdf;
    margin-top: 5px;
  }

  grid-area: 3 / 1 / 4 / 3;

  @media (max-width: 1100px) {
    grid-area: 5 / 1 / 6 / 2 !important;
  }
`;

const SendButton = styled.div`
  grid-area: 2 / 2 / 3 / 3;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 20px;
  & button {
    border: transparent;
    border-radius: 8px;
    box-sizing: border-box;
    height: 44px;
    width: 200px;
    background: linear-gradient(90deg, #4338b4, #7e46ad, #ac50aa, #cf58a8);
    color: white;
    font-size: 18px;
    cursor: pointer;
  }

  @media (max-width: 1100px) {
    justify-content: center;
  }
`;

const FlexCenter = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const Contactpage = () => {
  const success = () => {
    toast.success("Votre émail à bien été envoyé!", {
      position: "bottom-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  const probleme = () => {
    toast.error("Un problème est survenue!", {
      position: "bottom-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_93ptpt4",
        "template_p0p13fo",
        e.target,
        "8NVjWs7tEfYy7XtdB"
      )
      .then(
        (result) => {
          console.log(result.text, "SUCCESS");
          success(result.text);
        },
        (error) => {
          console.log(error.text, "FAILED");
          probleme(error.text);
        }
      );
    e.target.reset();
  };

  return (
    <Containersecondpage>
      <Fade bottom duration={2000}>
        <Globalstyle />
        <Balise id="contacter" />
        <TitleLeft>
          <Title id="contact" main>
            Contact
          </Title>
          <Title sub>Une question? Contactez Nous !</Title>
        </TitleLeft>
        <Info>
          <Contact>
            <FlexCenter>
              <a href="tel:0184200226" aria-label="Icon Phone" alt="">
                <ContactIcon src={LogoTel} alt="" />
              </a>
            </FlexCenter>
            <ContactInfo>
              <ContactLink href="tel:0184200226">
                <p>01 84 20 02 26</p>
              </ContactLink>
            </ContactInfo>
            <FlexCenter>
              <a href="mailto:contact@inspiire.fr" aria-label="Mail icon">
                <ContactIcon src={LogoMail} alt="" />
              </a>
            </FlexCenter>
            <ContactInfo>
              <ContactLink
                style={{ marginBottom: "15px" }}
                href="mailto:contact@inspiire.fr"
              >
                <p>contact@inspiire.fr</p>
              </ContactLink>
            </ContactInfo>
            <FlexCenter>
              <a
                href="https://www.google.fr/maps/place/92+Av.+Charles+de+Gaulle,+92200+Neuilly-sur-Seine/@48.882223,2.2676234,17z/data=!3m1!4b1!4m5!3m4!1s0x47e665666e315dab:0x2c17080222f2ea3c!8m2!3d48.8822195!4d2.2698121"
                aria-label="Map icon"
              >
                <ContactIcon src={LogoLieu} alt="" />
              </a>
            </FlexCenter>
            <ContactInfo>
              <ContactLink href="https://www.google.fr/maps/place/92+Av.+Charles+de+Gaulle,+92200+Neuilly-sur-Seine/@48.882223,2.2676234,17z/data=!3m1!4b1!4m5!3m4!1s0x47e665666e315dab:0x2c17080222f2ea3c!8m2!3d48.8822195!4d2.2698121">
                <p>92, Avenue Charle de Gaulle</p>
                <p>92200 Neuilly-Sur-Seine</p>
              </ContactLink>
            </ContactInfo>
          </Contact>
          <form onSubmit={sendEmail}>
            <Formulaire>
              <SectionInput>
                <Input>
                  <p>
                    Nom <span style={{ color: "red" }}>*</span>
                  </p>
                  <input
                    required
                    type="text"
                    name="lastname"
                    id="lastname"
                    className="form-control"
                    placeholder="Votre nom..."
                  ></input>
                </Input>
                <Input>
                  <p>
                    Prénom <span style={{ color: "red" }}>*</span>
                  </p>
                  <input
                    required
                    type="text"
                    name="name"
                    id="name"
                    className="form-control"
                    placeholder="Votre prénom..."
                  ></input>
                </Input>
                <Input>
                  <p>
                    Mail <span style={{ color: "red" }}>*</span>
                  </p>
                  <input
                    required
                    type="text"
                    name="mail"
                    id="mail"
                    className="form-control"
                    placeholder="exemple@gmail.com"
                  ></input>
                </Input>
                <Input>
                  <p>Téléphone</p>
                  <input
                    name="phone"
                    id="phone"
                    className="form-control"
                    placeholder="+33"
                  ></input>
                </Input>
                <Inputmessage>
                  <p>Message</p>
                  <textarea
                    required
                    type="text"
                    name="message"
                    id="message"
                    className="form-control"
                    rows="5"
                    placeholder="Votre message..."
                    style={{ resize: "none" }}
                  ></textarea>
                </Inputmessage>
              </SectionInput>
            </Formulaire>
            <SendButton>
              <button type="submit" id="submit" className="btn btn-info">
                Envoyer
              </button>
            </SendButton>
          </form>
          <ToastContainer
            position="bottom-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
        </Info>
      </Fade>
    </Containersecondpage>
  );
};

export default Contactpage;
